@import '../theme-bootstrap';
.product-foundation-vto {
  width: 100%;
  text-align: center;
  .tmp-product-name {
    width: 100vw;
    position: absolute;
    top: 0;
    #{$ldirection}: -100%;
    text-transform: uppercase;
    font-weight: bold;
    color: $color--black;
    font-family: $ano-bold_regular-font;
    font-size: 18px;
    line-height: 22px;
    text-align: $ldirection;
    height: auto;
    margin-bottom: 0;
  }
  &__shades-prev,
  &__shades-next {
    height: 10%;
    top: 14%;
  }
  &__container {
    position: fixed;
    top: 0;
    height: 100%;
    #{$ldirection}: 0;
    z-index: 9999;
    background-color: $color--white;
    width: 100%;
    ul.shade-list-vto {
      &::-webkit-scrollbar {
        display: none;
      }
      display: block;
      font-size: 0;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      li {
        margin: 0;
        width: 54px;
        height: 80px;
        display: none;
        text-align: center;
        &.active,
        &.vto-filter {
          display: inline-block;
          &.active {
            .vto-filter-text {
              display: none;
            }
          }
        }
        &.vto-filter {
          .vto-filter-text {
            color: $color--black;
            display: block;
            height: 21px;
            width: 45px;
            line-height: 0.9;
            float: $ldirection;
            font-size: 8px;
            padding-#{$ldirection}: 7px;
            text-align: $ldirection;
            margin: 4px 0;
            text-transform: uppercase;
            font-family: $helvetica-font;
          }
        }
        &.best-match {
          .star {
            background-image: url(/media/export/cms/foundation_tryon/star.png);
          }
          .vto-filter-text {
            width: 61px;
          }
          &.active {
            .star {
              background-image: none;
            }
          }
        }
        .vto-filter-text {
          display: none;
          margin-top: 5px;
          text-transform: capitalize;
          font-size: 12px;
        }
        position: relative;
        color: $color--white;
        font-size: 13px;
        white-space: initial;
        overflow: hidden;
        font-weight: bold;
        &.swatch--selected {
          border: 1px solid transparent;
          position: absolute;
          z-index: 10;
          width: 43px;
          height: 43px;
          #{$ldirection}: -1px;
          top: -3px;
          line-height: 42px;
          font-size: 11px;
          &.selected {
            border: 1px solid $color--white;
          }
        }
      }
      .swatch__container {
        width: 100%;
        height: 54px;
        margin: 0 auto;
      }
      .swatch__container .swatch--single {
        height: 100%;
      }
      .marked {
        border: 2px solid $color--black;
      }
      .star {
        background-image: none;
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: 88% 20%;
      }
    }
    .product-shades-vto-prev,
    .product-shades-vto-next {
      background-color: transparent;
      border: none;
      display: inline-block;
      color: $color--white;
      position: absolute;
      width: 10px;
      height: 54px;
      line-height: 100%;
      top: 0;
      z-index: 1000;
    }
    .product-shades-vto-prev {
      #{$ldirection}: 10px;
    }
    .product-shades-vto-next {
      #{$rdirection}: 10px;
    }
  }
  &__overlay {
    position: relative;
    height: 100%;
  }
  &__overlay-holder {
    position: absolute;
    top: 14%;
    width: 100%;
    text-align: center;
    color: $color--white;
  }
  &__overlay-image {
    img {
      width: auto;
      height: auto;
    }
  }
  &__overlay-close {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    #{$rdirection}: 10px;
    top: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 38px 38px;
    background-image: url('/media/export/cms/foundation_tryon/btn-close.png');
  }
  &__overlay-title,
  &__guide-title {
    font-size: 36px;
    margin: auto;
    letter-spacing: -0.17px;
    line-height: 35px;
    text-transform: uppercase;
    width: 280px;
    font-family: $ano-bold_regular-font;
  }
  &__overlay-copy {
    font-size: 16px;
    width: 100%;
    margin: 2% auto 9%;
    line-height: 22px;
    font-family: $helvetica-font;
  }
  &__guide {
    font-family: $ano-bold_regular-font;
    text-decoration: underline;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 28%;
  }
  &__overlay-check {
    .picker-checkbox {
      margin: 1em auto;
      display: inline-block;
    }
  }
  &__overlay-button {
    padding: 10px;
    height: 100%;
    background-color: $color--white;
    cursor: pointer;
    color: $color--black;
    width: 210px;
    margin: 18% auto 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1px;
    height: 54px;
    background-image: url(/media/export/cms/foundation_tryon/camera.png);
    background-repeat: no-repeat;
    background-position: 40px;
    font-family: $ano-bold_regular-font;
    &.disabled {
      color: $color--dark-gray;
    }
  }
  &__overlay-guide {
    background-color: $color--black;
    height: 100%;
    width: 100%;
    position: fixed;
    color: $color--white;
    text-align: center;
  }
  &__overlay-content {
    height: 100%;
  }
  &__guide-copy {
    font-family: $helvetica-font;
    font-size: 16px;
    text-align: $ldirection;
    line-height: 24px;
    margin: 5% auto;
    width: 280px;
  }
  &__guide-title {
    margin: 28% auto 10%;
  }
  &__youcam-module {
    width: 100%;
    @include breakpoint($bp--medium-up) {
      max-width: 560px;
      height: 685px;
      > div {
        margin: 0 auto;
      }
    }
  }
  &__btn {
    @include swap_direction(padding, 6px 9px 24px 42px);
    position: absolute;
    bottom: 0;
    font-family: $ano-bold_regular-font;
    text-transform: uppercase;
    height: 20px;
    font-size: 15px;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    margin: 0 auto;
    width: fit-content;
    background-image: url(/media/export/cms/foundation_tryon/camera.png);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 9px;
    z-index: 100;
    &.cta-vto {
      color: $color--black;
      border: 1px solid $color--black;
      line-height: 19px;
      background-color: $color--white;
    }
  }
  &__btn-container {
    float: $ldirection;
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
    .product-add-to-bag,
    .product-add-to-bag:hover {
      text-decoration: none;
    }
    .see-my-perfect,
    .see-all-foundation-shades {
      position: absolute;
      top: 0;
      text-transform: uppercase;
      font-size: 11px;
      text-decoration: underline;
      #{$rdirection}: 0;
      display: none;
      &.active {
        display: block;
      }
    }
  }
  &__info-container {
    float: $ldirection;
    padding-#{$ldirection}: 8px;
    display: inline-block;
    line-height: 0.9;
  }
  &__all-shades {
    position: absolute;
    #{$rdirection}: 7px;
    bottom: 45px;
    font-family: $ano-bold_regular-font;
    font-size: 12px;
    line-height: 24px;
    .see-all-foundation-shades,
    .see-my-perfect {
      text-transform: lowercase;
      text-decoration: underline;
      border-bottom: none;
    }
  }
  &__mobile-ctrl {
    .product-full__shades-prev,
    .product-full__shades-next {
      height: 54px;
    }
    .foundation-match-text {
      display: inline-block;
      float: $ldirection;
      width: 50%;
      font-family: $ano-bold_regular-font;
      text-transform: uppercase;
      font-size: 15px;
      padding: 5px 9px 0;
      color: $color--white;
    }
    .shade-name {
      display: inline-block;
      width: 50%;
      font-size: 37px;
      font-weight: bold;
    }
    visibility: hidden;
    li {
      a {
        display: none;
      }
    }
    &.active {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: $color--white;
      z-index: 100;
      visibility: visible;
      .star {
        background-image: none;
      }
    }
    .container {
      .product-match-header {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        visibility: hidden;
        &.active {
          visibility: visible;
        }
      }
      .product-name,
      .product-subline {
        // Any change done here should be reflected above for .tmp-product-name
        // parents need to be different to allow JS manipulation
        text-transform: uppercase;
        font-weight: bold;
        color: $color--black;
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        line-height: 22px;
        text-align: $ldirection;
        height: auto;
        margin-bottom: 0;
      }
      .product-full__price-text {
        width: auto;
        height: auto;
        line-height: 1;
        margin: 0;
        font-size: 11px;
      }
      .product-sub-heading {
        text-transform: uppercase;
        font-size: 9px;
        width: 100%;
        clear: both;
        text-align: $ldirection;
        letter-spacing: 2px;
        margin-bottom: 5px;
      }
      .product-price {
        font-size: 15px;
        display: inline-block;
        font-weight: bold;
        font-family: $ano-bold_regular-font;
        float: $ldirection;
        line-height: 16px;
      }
      .product-size {
        @include swap_direction(margin, -1px 0 0 6px);
        text-transform: uppercase;
        float: $ldirection;
      }
      .shade-list-vto {
        width: 100%;
      }
      .current-shade {
        width: 100%;
        font-family: $ano-bold_regular-font;
        text-align: $ldirection;
        .current-swatch {
          @include border-radius(7.5px);
          float: $ldirection;
          width: 15px;
          height: 15px;
          margin-#{$rdirection}: 5px;
        }
        span {
          font-size: 18px;
          line-height: 22px;
          width: 140px;
          max-height: 34px;
          overflow: hidden;
          font-family: $ano-bold_regular-font;
        }
      }
      .product-full__add-button {
        width: 100%;
        margin: 0;
        height: 36px;
        line-height: 36px;
        font-size: 11px;
      }
    }
  }
}

.no-scroll {
  position: fixed;
  max-width: 100%;
}

.device-mobile {
  .vto-ios-browser-error {
    #cboxClose {
      visibility: hidden;
    }
    .icon--remove {
      &::before {
        visibility: visible;
        font-size: 20px;
        position: absolute;
        #{$rdirection}: 10px;
        top: 10px;
      }
    }
  }
}
